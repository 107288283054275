<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <div>
                    <b-row>
                        <b-col
                            cols="12"
                            md="6"
                            sm="12"
                        >
                            <h1>{{ i18nT(`Job offers`) }}</h1>
                            <div class="mb-1">
                                <span class="text-muted">{{ i18nT(`Showing`) }} {{ dataMeta.from }} {{ i18nT(`to`) }}
                                  {{ dataMeta.to }} {{ i18nT(`of`) }} {{ dataMeta.of }}
                                  {{ i18nT(`entries`) }}</span>
                            </div>
                        </b-col>
                        <b-col
                            cols="12"
                            md="6"
                            sm="12"
                        >
							<b-dropdown
								v-if="iCan('job-offers', 'write')"
								id="add-new-dropdown"
								variant="primary"
								class="float-right"
								right

							>
								<template #button-content>
									<feather-icon icon="PlusCircleIcon" />
									{{ i18nT(`Add new`) }}
								</template>

								<b-dropdown-item>
									<b-link
										class="text-primary"
										:to="{
                                  name: 'create-edit-job-offer',
                                  params: { id: 'new' },
                                }"

									>
										<font-awesome-icon class="mr-25" :icon="['fas', 'plus-circle']" />
										{{ i18nT(`Add job offer`) }}
									</b-link>
								</b-dropdown-item>

								<b-dropdown-item
									v-b-modal.modal-export
									:disabled="totalDocuments == 0"
								>
									<div :class="{'text-primary': totalDocuments !== 0}">
										<font-awesome-icon class="mr-25 ml-25" :icon="['fas', 'file-export']" />
										{{ i18nT(`Export`) }}
									</div>
								</b-dropdown-item>

							</b-dropdown>
<!--                            <b-button-->
<!--                                variant="primary"-->
<!--                                class="float-right"-->
<!--                                :to="{-->
<!--                                  name: 'create-edit-job-offer',-->
<!--                                  params: { id: 'new' },-->
<!--                                }"-->
<!--                                v-if="iCan('job-offers', 'write')"-->
<!--                            >-->
<!--                                <feather-icon icon="PlusCircleIcon"/>-->
<!--                                {{ i18nT(`Add new`) }}-->
<!--                            </b-button>-->
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                            cols="12"
                            md="6"
                            v-if="controlVisible"
                        >
                            <b-form-group :label="i18nT(`Category`)">
                                <v-select
                                    v-model="category"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="offerCategories"
                                    :clearable="false"
                                    label="text"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="6"
                            v-if="controlVisible"
                        >
                            <b-form-group :label="i18nT(`Status`)">
                                <v-select
                                    v-model="status"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="offerStatuses"
                                    :clearable="false"
                                    label="text"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </div>

            <div>
                <div>
                    <div class="row mx-2">
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pl-0">
                            <v-select
                                v-model="perPage"
                                :options="perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block"
                            />
                            <b-dropdown
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                text="Actions"
                                size="md"
                                class="d-inline ml-1"
                                variant="outline-secondary"
                                v-if="selectedItems.length > 0 && iCan('job-offers', 'write')"
                            >
                                <b-dropdown-item @click="onBulkCopy(data)">
                                    <feather-icon icon="CopyIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onBulkDelete(data)">
                                    <feather-icon
                                        icon="TrashIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{
                                            i18nT(`Delete`)
                                        }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                            <!-- <b-dropdown
                              size="md"
                              class="d-inline ml-1"
                              variant="outline-secondary"
                            >
                              <template v-slot:button-content>
                                <feather-icon icon="ExternalLinkIcon" />&nbsp;&nbsp;{{
                                  i18nT(`Export`)
                                }}
                              </template>
                              <b-dropdown-item @click="onExportExcel()">
                                <feather-icon icon="CopyIcon" />
                                <span class="align-middle ml-50">{{ i18nT(`Excel`) }}</span>
                              </b-dropdown-item>
                              <b-dropdown-item @click="onExportCsv()">
                                <feather-icon icon="CopyIcon" />
                                <span class="align-middle ml-50">{{ i18nT(`CSV`) }}</span>
                              </b-dropdown-item>
                            </b-dropdown> -->
                        </div>
                        <!-- search input -->
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pr-0">
                            <div class="custom-search d-flex justify-content-end">
                                <b-form-group>
                                    <div
                                        role="group"
                                        class="input-group input-group-merge"
                                    >
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <feather-icon icon="SearchIcon"/>
                                            </div>
                                        </div>
                                        <b-form-input
                                            v-model="searchQuery"
                                            :placeholder="i18nT(`Search`)"
                                            type="text"
                                            class="d-inline-block"
                                        />
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <b-table
                        ref="refDocumentListTable"
                        :items="fetchOffers"
                        responsive
                        :fields="computedColumns"
                        primary-key="id"
                        :sort-by.sync="sortBy"
                        show-empty
                        :empty-html="emptyHtml"
                        :sort-desc.sync="isSortDirDesc"
                        :sort-compare="mySortCompare"
                        class="position-relative"
                        @refreshed="onTableRefresh"
                        v-model="currentItems"
                    >
                        <template v-slot:head(checkbox)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <b-form-checkbox
                                class="d-inline-block pl-0"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                            </b-form-checkbox>
                        </template>

                        <template #head(action)="data">
                            <span>{{ data.label.toUpperCase() }}</span> 
                            <div class="sk-column-control">
                                <b-dropdown
                                    no-caret
                                    variant="button"
                                    toggle-class="p-0"
                                    :right="true"
                                    class="pr-1 pl-1 d-flex sk-column-filter"
                                    v-if="betaFeature"
                                    v-b-tooltip = "i18nT(`Customize columns`)"
                                >
                                    <template #button-content>
                                        <feather-icon
                                            icon="SettingsIcon"
                                            size="18"
                                            class="text-right"
                                        />
                                    </template>

                                    <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">
                                        <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                                    </div>
                                </b-dropdown>
                            </div>
                        </template>

                        <template #cell(checkbox)="data">
                            <b-form-checkbox
                                class="pl-0"
                                v-model="selectedItems"
                                :value="data.item.offer.Id"
                            />
                        </template>

                        <template #cell(name)="data">
                            <b-link
                                :to="{ name: 'view-job-offer', params: { id: data.item.offer.Id } }"
                                class="font-weight-bold"
                            >
                                {{ data.item.offer.Label }}
                            </b-link>
                            <p class="mb-0 font-small-3 text-muted">{{getNameByDepartmentId(data.item.offer.Department)}}</p>
                        </template>

                        <template #cell(category)="data">
                            {{ getNameByCategoryId(data.item.offer.Category) }}
                        </template>

                        <template #cell(contractType)="data">
                            {{ getNameByContractTypeId(data.item.offer.ContractType) }}
                        </template>

                        <template #cell(created)="data">
                            {{ data.item.offer.CreatedAt | date }}
                        </template>

                        <template #cell(applicants)="data">
                            <!-- avatar group -->
                            <b-avatar-group
                                class=""
                                size="34px"
                            >
                                <b-avatar
                                    v-for="applicant in data.item.first_five_applied"
                                    :key="applicant.Id"
                                    :text="(applicant.FirstName ? applicant.FirstName.charAt(0) : '') + (applicant.LastName ? applicant.LastName.charAt(0) : '')"
                                    v-b-tooltip.hover.bottom="applicant.Label"
                                    :src="applicant.ImageUrl"
                                    class="pull-up badge-light-primary"
                                />
                                <h6
                                    v-if="data.item.remaining_applied_count"
                                    class="align-self-center cursor-pointer ml-1 mb-0"
                                >
                                    +{{ data.item.remaining_applied_count }}
                                </h6>
                            </b-avatar-group>
                        </template>

                        <template #cell(status)="data">
              <span
                  v-if="data.item.offer.PublicationStatus == 'ACTIVE'"
                  class="badge badge-light-success badge-pill is-uppercase"
              >{{ data.item.offer.Status }}</span>
                            <span
                                v-else
                                class="badge badge-light-secondary badge-pill is-uppercase"
                            >{{ data.item.offer.Status }}</span>
                        </template>

                        <template #cell(action)="data">
                            <b-dropdown
                                variant="link"
                                toggle-class="p-0"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                            >
                                <template #button-content>
                                    <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                    />
                                </template>
                                <b-dropdown-item :to="{
                                        name: 'create-edit-job-offer',
                                        params: { id: data.item.offer.Id },
                                      }">
                                                        <feather-icon icon="EditIcon"/>
                                                        <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                                                    </b-dropdown-item>
                                                    <b-dropdown-item
                                                        v-if="
                                        data.item.offer.PublicationStatus === 'ACTIVE' &&
                                        publicUrl(data.item.offer) !== ''
                                      "
                                    :href="publicUrl(data.item.offer)"
                                    target="_blank"
                                >
                                    <feather-icon icon="EyeIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Preview`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onChangeStatus(data.item)">
                                    <feather-icon icon="PowerIcon"/>
                                    <span class="align-middle ml-50">{{
                                            data.item.offer.PublicationStatus === "ACTIVE"
                                                ? i18nT(`Deactivate`)
                                                : i18nT(`Activate`)
                                        }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onCopy(data)">
                                    <feather-icon icon="CopyIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onDelete(data)">
                                    <feather-icon
                                        icon="TrashIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{
                                            i18nT(`Delete`)
                                        }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>
                </div>

                <div class="mx-1 mb-2">
                    <b-row>
                        <b-col
                            cols="12"
                            sm="6"
                            class="
                                d-flex
                                align-items-center
                                justify-content-center justify-content-sm-start
                              "
                        >
                            <b-form-checkbox
                                class="d-inline-block mx-1"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                                {{ i18nT(`All`) }}
                            </b-form-checkbox>
                            <span class="text-muted">{{ i18nT(`Showing`) }} {{ dataMeta.from }} {{ i18nT(`to`) }}
                {{ dataMeta.to }} {{ i18nT(`of`) }} {{ dataMeta.of }}
                {{ i18nT(`entries`) }}</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
                        >
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalDocuments"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-card>


		<modal-export module="offers" :allFilters="allFilters"/>

    </div>
</template>

<script>
import {
    BCard,
    // BCardHeader,
    BCardFooter,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTabs,
    BTab,
    // BListGroup, BListGroupItem,
    BFormCheckbox,
    //BFormSelect,
    BFormInput,
    BBadge,
    BAvatar,
    BAvatarGroup,
    // VBModal
    VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useJobOfferList from './useJobOfferList'
import {avatarText} from '@core/utils/filter'
import {
    dictToSelectArrayWithTitle,
    dictToSelectArray
} from '@core/utils/utils'
import router from '@/router'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAuth from "@/auth/useAuth";
import ModalExport from "@/views/common/ModalExport/ModalExport.vue";

export default {
    components: {
		ModalExport,
        BRow,
        BCol,
        BFormGroup,
        BCard,
        // BCardHeader,
        BCardFooter,
        BCardBody,
        BButton,
        BTable,
        BLink,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTabs,
        BTab,
        // BListGroup,
        // BListGroupItem,
        vSelect,
        BFormCheckbox,
        //BFormSelect,
        BFormInput,
        BBadge,
        BAvatar,
        BAvatarGroup
        // VBModal
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip
    },
    data() {
        return {
            pagination: {},
            selectedItems: [],
            currentItems: [],
            selectAllStatus: false,
            offerStatuses: [],
            filterOffers: [],
            offerCategories: [],
            offerContractTypes: [],
            offerDepartments: [],
            searchTerm: '',
            avalableFields: [
              {label: this.i18nT(`Category`), key: 'category', visible: true},
              {label: this.i18nT(`Contract type`), key: 'contractType', visible: true},
              {label: this.i18nT(`Created`), key: 'created', visible: true},
            ],
            controlVisible:true,
        }
    },
    created() {
        this.getOfferStatuses()
        this.getFilterOffers()
        this.getOfferCategories()
        this.getOfferContractTypes()
        this.getOfferDepartments()

        let storePreferences = useAuth.getColumnPreferences('jobOffers')
          if(storePreferences) {
            this.avalableFields = storePreferences
        }
        this.fetchOffers(null, this.determineVisibility);
    },
    methods: {
        determineVisibility(invoiceArray) {
          if(invoiceArray.length===0){
            this.controlVisible = false;
          }
        },
        mySortCompare(a, b, key) {
            if (key === 'created') {
                // Assuming the date field is a `Date` object, subtraction
                // works on the date serial number (epoch value)
                return a[key] - b[key]
            } else {
                // Let b-table handle sorting other fields (other than `date` field)
                return false
            }
        },
        getOfferCategories() {
            this.$http.get(`system/jobCategories`).then(({data}) => {
                this.offerCategories = dictToSelectArray(data.data)
                this.offerCategories.unshift({
                    value: '',
                    text: this.i18nT(`All`)
                })
            })
        },
        getOfferContractTypes() {
            this.$http.get(`system/contractTypes`).then(({data}) => {
                this.offerContractTypes = dictToSelectArray(data.data)
            })
        },
        getOfferDepartments() {
            this.$http.get(`system/departments`).then(({data}) => {
                this.offerDepartments = dictToSelectArray(data.data)
            })
        },
        getOfferStatuses() {
            this.$http.get(`offers/offerStatuses`).then(({data}) => {
                this.offerStatuses = dictToSelectArray(data.data)
            })
        },
        getFilterOffers() {
            this.$http.get(`system/jobCategories`).then(({data}) => {
                this.filterOffers = dictToSelectArray(data.data)
            })
        },
        removeLabel(label) {
            console.log(label)
        },
        getLabels(labelArray) {
            return dictToSelectArrayWithTitle(labelArray)
        },
        getTypeName(typeId) {
            const status = this.offerStatuses.find(type => type.value == typeId)
            return status ? status.text : ''
        },
        getNameByCategoryId(categoryId) {
            const category = this.offerCategories.find(cat => cat.value == categoryId)
            return category ? category.text : ''
        },
        getNameByDepartmentId(departmentId) {
            const department = this.offerDepartments.find(
                dep => dep.value == departmentId
            )
            return department ? department.text : ''
        },
        getNameByContractTypeId(contractTypeId) {
            const contractType = this.offerContractTypes.find(
                con => con.value == contractTypeId
            )
            return contractType ? contractType.text : ''
        },
        getAvatarName(name) {
            if (name) {
                return name
                    .split(' ')
                    .map(namePart => {
                        return namePart[0]
                    })
                    .join('')
                    .toUpperCase()
            } else {
                return ''
            }
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        onCopy(offer) {
            this.$http
                .post(`offers/copy?offer_id=${offer.item.offer.Id}`)
                .then(res => {
                    this.refetchData()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(res),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })
                })
        },
        onDelete(offer) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`offers?id=${offer.item.offer.Id}`)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.refetchData()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onBulkCopy() {
            this.$http
                .post(`offers/copy?offer_id=${this.selectedItems.join(',')}`)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(res),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                    this.selectedItems = []
                    this.selectAllStatus = false
                    this.refetchData()
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })
                })
        },
        onBulkDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`offers?id=${this.selectedItems.join(',')}`)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.refetchData()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.offer.Id)
            } else {
                this.selectedItems = []
            }
        },
        onPutInFolder(data) {
            console.log(data)
            // this.$bvModal.show('modal-pipeline');
        },
        onChangeStatus(offer) {
            this.$http
                .post(
                    `offers/changeStatus?id=${offer.offer.Id}&status=${
                        offer.offer.PublicationStatus === 'ACTIVE'
                            ? 'DEACTIVATED'
                            : 'ACTIVE'
                    }`
                )
                .then(res => {
                    // this.currentItems = this.currentItems.map((item, index) => {
                    //   if (item.Id === offer.Id) {
                    //     let item = this.currentItems[index]
                    //     if (item.PublicationStatus === 'ACTIVE') {
                    //       item.PublicationStatus = 'DEACTIVATED'
                    //     } else {
                    //       item.PublicationStatus = 'ACTIVE'
                    //     }
                    //     return item
                    //   }
                    //   return item
                    // })
                    this.refetchData()

                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(res),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })
                })
        },
        publicUrl(offer) {
            if (offer && offer.Label) {
                return `${window.location.protocol}//${
                    window.location.host
                }/apply/${offer.Label.split(' ').join('-')}_${offer.Id}`
            }
            return ''
        }
    },
    setup() {
        const {
            fetchOffers,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            category,
            isSortDirDesc,
            refDocumentListTable,
            status,
            group,
            offers,
            sortBy,
            refetchData
        } = useJobOfferList(router.currentRoute.meta.template === 1)

        return {
            fetchOffers,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            category,
            isSortDirDesc,
            refDocumentListTable,
            status,
            group,
            avatarText,
            offers,
            sortBy,
            refetchData
        }
    },
    computed: {
        emptyHtml() {
          return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
              + this.i18nT(`Here, you can create and manage your job offers. No job offer has been created yet.`) + "</h4>"
              +"<button class='btn btn-outline-secondary mt-2'  onclick='window.location.href=\"/re/job-offers/edit/new\"'>" + this.i18nT(`Create job offer`) + "</button>"
        },
        computedColumns() {


            let col = [...this.tableColumns]

            for(let i = 0; i < this.avalableFields.length; i++) {
              if(!this.avalableFields[i].visible) {
                col = col.filter(c => c.key !== this.avalableFields[i].key)
              }
            }
            useAuth.setColumnPreferences('jobOffers', this.avalableFields);

            return col
        },
		allFilters(){
			const params = {
				export: 1,
				sort_order: this.isSortDirDesc ? 'DESC' : 'ASC',
				search: this.searchQuery,
				sort_by: this.sortBy,
				template: 1,
			}

			if (this.status != null && this.status.value !== '') {
				params['filters[status]'] = this.status.value;
			}
			if (this.category != null && this.category.value !== '') {
				params['filters[category]'] = this.category.value;
			}

			return params;
		}
    }

}
</script>

<style lang="scss">
.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';

.section-title {
    font-size: 11px;
}

.document-name {
    display: inline;
    line-height: 32px;
}

.document-actions-bar {
    float: right;
}

.ql-editor {
    min-height: 200px;
}

.tag-container {
    display: inline-block;
    background: #e6e6e6;
    color: #626262;
    padding: 3px 9px;
    font-size: 11px;
    font-weight: 600;
    margin: 4px;
    border-radius: 5px;

    .handler {
        cursor: pointer;
    }
}

.cv-card {
    min-height: 180px;

    span {
        font-size: 12px;
    }

    .is-uppercase {
        text-transform: uppercase;
    }
}
</style>
